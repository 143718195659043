.object-one__row {
  display: flex;
  flex-direction: row;

  flex-wrap: no-wrap;
  @include m1000() {
    flex-direction: column;

    align-items: center;
  }
}
.object-one__item {
  width: 52%;
}
.object-one__item--pic {
  @include m1000() {
    width: 90%;
    max-width: 600px;
  }
}
.object-one__item--text {
  z-index: 1;
  margin: 30px -3% 0 0;
  padding: 25px 70px 25px 40px;
  border: 4px solid #49a3df;

  @include m1000() {
    width: 100%;
    max-width: 800px;
    margin: 0 0 -50px 0;
    padding: 25px 40px 70px 40px;
  }
  @include m425() {
    margin: 0 0 -30px 0;
    padding: 25px 20px 40px 20px;
  }
  .objects-item__tags {
    margin: 0 0 20px 0;
    padding-bottom: 15px;
    border-bottom: 4px solid #49a3df;
  }
  p {
    font-size: 14px;
    line-height: 18px;
    color: #424242;

    @include pt(regular);
  }
}
.object-one__item--title {
  font-size: 30px;
  padding: 20px 0;
  color: #49a3df;
  border-bottom: 4px solid #49a3df;

  @include gilroy(bold);
  @include m425() {
    font-size: 22px;
    padding: 20px 0 10px 0;
  }
}

.objects-item__controls {
  display: flex;
  flex-direction: row;

  justify-content: space-between;
}
.objects-item__control {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 125px;
  margin-top: 20px;

  align-items: center;
  @include m680() {
  }
  &:hover {
    span {
      text-decoration: underline;
    }
  }
  &.left {
    svg {
      margin-right: 7px;
      transform: rotate(180deg);
    }
  }
  &.right {
    flex-direction: row-reverse;
    text-align: right;
    svg {
      margin-left: 7px;
    }
  }
  svg {
    width: 40px;
    height: 60px;

    fill: #49a3df;
  }
  span {
    color: #49a3df;
  }
  a {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
