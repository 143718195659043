.home-page {
  background-image: url('../img/bg-main.jpg');
  background-position: 50% 50%;
  background-size: cover;
}
.main-container {
  padding-top: 0;
  padding-bottom: 0;
}
.main-column {
  position: relative;
  float: right;
  width: 440px;
  min-height: 100vh;
  margin-right: 80px;
  padding: 130px 30px 50px 30px;
  background-image: url('../img/bg-main-column.png');
  background-repeat: repeat-x;
  background-size: contain;
  box-shadow: 0 0 76px rgba(0, 25, 69, .9);

  @include m1200() {
    margin-right: 0;
  }
  @include m1100() {
    width: 380px;
  }
  @include m1000() {
    float: none;
    width: 90%;
    max-width: 700px;
    margin: 0 auto;
    padding-top: 450px;
  }
  @include m680() {
    padding-top: 380px;
  }
  @include m530() {
    padding-top: 110px;
  }
  @include m375() {
    padding-right: 20px;
    padding-left: 20px;
  }
}
.main-column__title {
  font-size: 72px;
  line-height: .8;
  position: relative;
  left: -250px;
  text-transform: uppercase;
  color: #fff;

  @include gilroy(bold);
  @include m1000() {
    left: 0;
  }
  @include m530() {
    font-size: 60px;
  }
  @include m375() {
    font-size: 50px;
  }
  span {
    font-size: 60px;

    @include m530() {
      font-size: 40px;
    }
    @include m375() {
      font-size: 30px;
    }
  }
}
.main-column__text {
  position: relative;
  padding: 25px 0 18px 0;
  &.line {
    &::after {
      position: absolute;
      right: 8%;
      bottom: 0;
      left: 8%;
      display: block;
      height: 1px;
      content: '';
      background-color: #fff;
    }
  }
  p {
    font-size: 16px;
    line-height: 20px;
    color: #fff;

    @include pt(regular);
    @include m375() {
      font-size: 14px;
      line-height: 18px;
    }
  }
}
.main-column__btn {
  font-size: 18px;
  position: relative;
  display: block;
  margin: 20px auto 0;
  padding: 20px 10px 17px 10px;
  transition: .5s;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  color: #fff;
  text-shadow: 0 0 30px rgba(0, 255, 255, .9);

  @include gilroy(bold);
  @include m1000() {
    max-width: 400px;
  }
  @include m1000() {
    max-width: 300px;
  }
  @include m425() {
    font-size: 14px;
    max-width: 230px;
  }
  &:hover {
    color: #fff;
    text-shadow: none;
    rect {
      transition: all 1.35s cubic-bezier(.19, 1, .22, 1);
      transition: .5s;

      stroke-width: 5;
      stroke-dasharray: 20, 630;
      stroke-dashoffset: 40;
      @include m1100() {
        stroke-dasharray: 20, 540;
      }
      @include m1000() {
        stroke-dasharray: 20, 660;
      }
      @include m530() {
        stroke-dasharray: 20, 510;
      }
      @include m425() {
        stroke-dasharray: 20, 410;
      }
    }
  }
  svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 55px;

    @include m425() {
      height: 52px;
    }
  }
  rect {
    transition: .5s;

    fill: none;
    stroke: #fff;
    stroke-width: 4;
    stroke-dasharray: 830, 0;
  }
}
.main-column__copyright {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  padding: 5px 10px;

  justify-content: space-around;
  flex-wrap: wrap;
}
.main-column__copyright--text,
.main-column__copyright--link {
  font-size: 12px;
  display: inline-block;
  text-decoration: none;
  color: #fff;
}
