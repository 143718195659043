.error-page {
  background-image: url('../img/bg-404.jpg');
  background-position: 50% 50%;
  background-size: cover;
}
.error-page-inn {
  display: flex;
  flex-direction: row;
  min-height: 100vh;

  justify-content: center;
  align-items: center;
}
.error {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 420px;
  margin: 120px 0;
  padding: 20px 30px;

  @include m425() {
    padding: 20px 10px;
  }
}
.error-header {
  position: relative;
  margin-bottom: 30px;
  &::before {
    position: absolute;
    top: -25px;
    right: -10px;
    width: 210px;
    height: 120px;
    content: '';
    border: 2px solid #49a3df;

    @include m425() {
      width: 150px;
      height: 100px;
    }
  }
  span {
    font-size: 44px;
    line-height: 1;
    position: relative;
    text-transform: uppercase;
    color: #fff;

    @include gilroy(bold);
    @include m425() {
      font-size: 34px;
    }
  }
  .error-title {
    font-size: 110px;
    line-height: 1;
    position: relative;
    margin-top: -10px;
    text-align: right;
    color: #fff;

    @include gilroy(bold);
    @include m425() {
      font-size: 90px;
    }
  }
}
.error-text {
  margin-bottom: 30px;
  text-align: center;
  p {
    font-size: 24px;
    line-height: 28px;
    text-transform: uppercase;
    color: #fff;

    @include gilroy(bold);
    @include m425() {
      font-size: 18px;
      line-height: 24px;
    }
  }
}
.error__btn {
  font-size: 18px;
  position: relative;
  display: block;
  max-width: 300px;
  margin: 20px auto 0;
  padding: 20px 10px 17px 10px;
  transition: .5s;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  color: #fff;
  text-shadow: 0 0 30px rgba(0, 255, 255, .9);

  @include gilroy(bold);
  @include m425() {
    font-size: 14px;
    max-width: 230px;
  }
  &:hover {
    color: #fff;
    text-shadow: none;
    rect {
      transition: all 1.35s cubic-bezier(.19, 1, .22, 1);
      transition: .5s;

      stroke-width: 5;
      stroke-dasharray: 20, 510;
      stroke-dashoffset: 40;
      @include m425() {
        stroke-dasharray: 20, 410;
      }
    }
  }
  svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 55px;

    @include m425() {
      height: 52px;
    }
  }
  rect {
    transition: .5s;

    fill: none;
    stroke: #fff;
    stroke-width: 4;
    stroke-dasharray: 830, 0;
  }
}
