.object-one-slider {
  position: relative;
  z-index: 5;
  width: 99.5%;
  margin-bottom: 50px;
  box-shadow: 0 0 20px 5px rgba(69, 155, 212, .38);
  ul {
    margin: 0;
    padding: 0;
  }
  li {
    padding: 0;
  }
  .bx-wrapper {
    overflow: hidden;
    margin: 0 0 10px 0;
    border: none;
    box-shadow: none;
    img {
      display: block;
      width: 100%;
    }
  }
  .bx-controls-direction {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    visibility: hidden;
    margin: auto;
  }
  .bx-wrapper .bx-controls-direction a {
    width: 30px;
    height: 40px;
    transition: .5s;
    background-color: #49a3df;
    &:hover {
      transition: .5s;
      background-color: #1b73ae;
    }
  }
  .bx-wrapper .bx-prev {
    left: 0;
    visibility: visible;
    background: url('../img/object-slider-arr.png') 8px 10px no-repeat;
    &:after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: block;
      margin: auto;
      content: '';
      transition: .5s;
      opacity: 0;
      background: url('../img/object-slider-arr.png') 8px 10px no-repeat;
    }
    &:hover {
      &:after {
        transition: .5s;
        opacity: 1;
      }
    }
  }
  .bx-wrapper .bx-next {
    right: 0;
    visibility: visible;
    background: url('../img/object-slider-arr.png') -68px 10px no-repeat;
    &:after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: block;
      margin: auto;
      content: '';
      transition: .5s;
      opacity: 0;
      background: url('../img/object-slider-arr.png') -68px 10px no-repeat;
    }
    &:hover {
      &:after {
        transition: .5s;
        opacity: 1;
      }
    }
  }
}
