.hamburger {
  position: absolute;
  z-index: 101;
  top: -5px;
  right: -5px;
  display: none;
  width: 28px;
  height: 28px;
  margin: 20px 20px 0 0;
  cursor: pointer;

  @include m1000() {
    display: block;
  }
  span {
    position: relative;
    display: block;
  }
  span:after,
  span:before {
    position: absolute;
    top: 9px;
    left: 0;
    content: '';
  }
  span:after {
    top: 18px;
  }
  span,
  span:after,
  span:before {
    width: 100%;
    height: 2px;
    transition: all .3s;
    border-radius: 2px;
    background-color: #fff;
  }
}
.hamburger.on span {
  background-color: transparent;
  &:before {
    transform: rotate(45deg) translate(-1px, 0px);
  }
  &:after {
    transform: rotate(-45deg) translate(6px, -7px);
  }
}
