.objects__row {
  position: relative;
  display: flex;
  flex-direction: row;
  margin-bottom: 50px;

  align-items: center;
  @include m680() {
    flex-direction: column;
  }
  &:hover {
    .objects-item-pic {
      &::before {
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transition: .5s;
      }
    }
  }
}
.objects-item-pic {
  position: relative;
  margin-right: 30px;
  margin-bottom: 30px;
  box-shadow: 0 0 20px 5px rgba(69, 155, 212, .38);
  &::before {
    position: absolute;
    top: 20px;
    right: -20px;
    bottom: -20px;
    left: 20px;
    content: '';
    transition: .5s;
    border: 4px solid #49a3df;
  }
}
.objects-item-pic__wrap {
  overflow: hidden;
  width: 250px;
  height: 250px;
  img {
    display: block;
    width: 100%;
    min-height: 100%;

    object-fit: cover;
  }
}
.objects-item-text {
  padding: 0 30px;

  flex: 0 1 auto;
}
.objects-item__title {
  line-height: 30px;
  margin-bottom: 20px;
  color: #0570a1;

  @include gilroy(bold);
}
.objects-item__descr {
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 10px;
  color: #424242;

  @include pt(regular);
}
.objects-item__tags {
  display: flex;
  flex-direction: row;
  margin: 30px 0 30px 0;

  justify-content: flex-start;
  align-items: center;
}
.objects-item__tag-descr {
  font-size: 18px;
  margin-right: 20px;
  text-transform: uppercase;
  color: #49a3df;

  @include pt(bold);
}
.objects-item__tag-name {
  font-size: 18px;
  color: #424242;

  @include pt(regular);
}
.objects-item-link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
