.page-header {
  background-size: cover;
  position: relative;
  z-index: 1;
}
.page-header__logo {
  position: relative;
  width: 175px;
  height: 70px;
  margin-bottom: 20px;
  &:hover {
    svg {
      transition: .5s;

      fill: #a5e6f4;
    }
  }
  svg {
    position: relative;
    width: 100%;
    height: 100%;
    transition: .5s;

    fill: #fff;
  }
}
.page-header__logo-link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.page-header__title {
  font-size: 36px;
  line-height: 1.1;
  margin-bottom: 0;
  color: #fff;

  @include gilroy(bold);
  @include m425() {
    font-size: 32px;
  }
}
.breadcrumbs__list {
  display: flex;
  flex-direction: row;
  margin: 10px 0 0 0;
  padding: 0;

  align-items: flex-start;
  .breadcrumbs__item:last-child {
    .breadcrumbs__link {
      text-decoration: none;
      pointer-events: none;
    }
  }
}
.breadcrumbs__item {
  list-style: none;
  &::before {
    display: none;
  }
  &::after {
    display: inline-block;
    width: 4px;
    height: 4px;
    margin: 0 10px 2px 10px;
    content: '';
    background-color: #49a3df;
  }
  &:last-child {
    &::after {
      display: none;
    }
    .breadcrumbs__link {
      color: #83aab8;
    }
  }
}
.breadcrumbs__link {
  line-height: 24px;
  text-transform: lowercase;
  color: #fff;
  line-height: 1;
  &:hover {
    text-decoration: none;
  }
}
