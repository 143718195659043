#menu-line {
  display: none;
  @include m1000() {
    display: block;
  }
}
.top-menu-wrap {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  max-width: 1140px;
  height: 100%;
  margin: auto;
  padding: 30px 30px 0 0;

  @include m1000() {
    top: 0;
    padding: 0;
  }
}
.top-menu,
.top-menu__list,
.top-menu__item,
.top-menu__link {
  line-height: 1;
  display: block;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  border: 0;
}
.top-menu {
  position: relative;
  display: inline-block;
  float: right;
  width: auto;

  @include m1000() {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}
.top-menu__list {
  position: relative;
  z-index: 15;
  height: 100%;

  @include m1000() {
    display: none;
    width: 100%;
    background-color: rgba(1,19,52,1);
  }
  &.open {
    z-index: 10;
  }
}
.top-menu__item {
  float: left;
  margin: 0 5px;
  transition: .5s ease;
  border: 2px solid transparent;

  @include m1000() {
    display: block;
    float: none;
    margin: 0;
    border: none;
    border-top: 1px solid rgba(120, 120, 120, .15);
  }
  &::before {
    display: none;
  }
  &:hover {
    transition: .5s ease;
    border: 2px solid #a5e6f4;

    @include m1000() {
      border: none;
      border-top: 1px solid rgba(120, 120, 120, .15);
    }
    .top-menu__link {
      color: #fff;
    }
  }
  &.active {
    transition: .5s ease;
    border: 2px solid #225f9c;

    @include m1000() {
      border: none;
    }
  }
  &.parent {
    .top-menu__link {
      padding-right: 40px;
      &::after {
        font-size: 22px;
        position: absolute;
        top: 5px;
        right: 20px;
        width: 7px;
        height: 7px;
        content: '+';
        transition: .5s ease;

        @include m1000() {
          display: none;
        }
      }
    }
  }
}
.top-menu__link {
  font-size: 16px;
  padding: 8px 20px;
  text-decoration: none;
  letter-spacing: .35px;
  text-transform: uppercase;
  color: #fff;

  @include pt(bold);
  @include m1000() {
    padding: 15px 30px;
  }
}

.top-menu__sublist {
  position: absolute;
  left: -9999px;
  margin: 0;
  padding: 0;

  @include m1000() {
    position: relative;
    right: auto;
    left: 0;
    width: 100%;
    margin: 0;
  }
}
.top-menu__item:hover {
  .top-menu__sublist {
    left: auto;
    padding-top: 15px;

    @include m1000() {
      padding-top: 0;
    }
  }
}
.top-menu__subitem {
  position: relative;
  padding: 0;
  background-color: rgba(1,19,52,.9);

  @include m1000() {
    border-top: 1px solid rgba(120, 120, 120, .15);
  }
  &::before {
    display: none;
  }
  &:last-child .top-menu__sublink::after {
    display: none;
  }
}
.top-menu__sublink {
  font-size: 16px;
  display: inline-block;
  min-width: 270px;
  padding: 12px 20px;
  transition: color .5s ease;
  text-decoration: none;
  text-transform: uppercase;
  color: #fff;

  @include pt(bold);
  @include m1000() {
    padding: 15px 50px;
  }
  &:hover {
    color: #a5e6f4;
  }
  &::after {
    position: absolute;
    right: 20px;
    bottom: 0;
    left: 20px;
    display: block;
    height: 1px;
    content: '';
    background-color: #a5e6f4;

    @include m1000() {
      display: none;
    }
  }
}
.submenu-button {
  @include m1000() {
    position: absolute;
    z-index: 10;
    top: 0;
    right: 0;
    display: block;
    width: 52px;
    height: 48px;
    cursor: pointer;
    border-left: 1px solid rgba(120, 120, 120, .15);
    &::before,
    &::after {
      position: absolute;
      z-index: 99;
      content: '';
      background: #fff;
    }
    &::before {
      top: 24px;
      left: 21px;
      display: block;
      width: 11px;
      height: 1px;
    }
    &::after {
      top: 19px;
      left: 26px;
      display: block;
      width: 1px;
      height: 11px;
    }
    &.submenu-opened:after {
      display: none;
    }
  }
}
