.footer {
  background-color: #000c1f;
  position: relative;
}
.footer__list {
  display: flex;
  flex-direction: row;

  flex-wrap: wrap;
  justify-content: space-between;
  @include m680() {
    flex-direction: column;
    width: 100%;

    justify-content: center;
    align-items: center;
  }
}
.footer__item {
  display: flex;
  flex-direction: row;
  padding: 5px 15px;
  border-right: 1px solid #459bd4;

  align-items: center;
  @include m1024() {
    padding: 5px 5px 5px 10px;
    &:nth-child(n+4) {
      border-right: none;
    }
  }
  @include m680() {
    border-right: none;
  }
  &:nth-child(n+5) {
    border-right: none;
  }
}
.footer__link {
  font-size: 14px;
  line-height: 18px;
  text-decoration: none;
  color: #fff;

  @include pt(regular);
}
.footer__item--logo {
  position: relative;
  width: 13%;

  @include m1024() {
    width: 17%;
  }
  @include m680() {
    width: 270px;
    padding-left: 2px;
  }
  &:hover {
    svg {
      transition: .5s;

      fill: #a5e6f4;
    }
  }
  svg {
    width: 95%;
    max-width: 130px;
    max-height: 50px;
    transition: .5s;

    fill: #fff;
  }
  .footer__link--logo {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
.footer__item--adress {
  width: 28%;

  @include m1024() {
    width: 32%;
  }
  @include m680() {
    width: 270px;
  }
  svg {
    width: 33px;
    height: 33px;
    margin-right: 10px;

    fill: #459bd4;
  }
}
.footer__item--phone {
  width: 21%;

  @include m1024() {
    width: 25%;
  }
  @include m680() {
    width: 270px;
  }
  svg {
    width: 33px;
    height: 33px;
    margin-right: 10px;

    fill: #459bd4;
  }
}
.footer__item--mail {
  width: 22%;

  @include m1024() {
    width: 26%;
  }
  @include m680() {
    width: 270px;
    padding-left: 13px;
  }
  svg {
    width: 30px;
    height: 33px;
    margin-right: 10px;

    fill: #459bd4;
  }
}
.footer__item--copyright {
  display: flex;
  flex-direction: column;
  width: 16%;

  justify-content: center;
  align-items: flex-start;
  @include m1024() {
    flex-direction: row;
    width: 100%;

    justify-content: center;
    align-items: center;
  }
  span,
  a {
    font-size: 12px;
    line-height: 14px;
    min-width: 100%;
    text-decoration: none;
    color: #fff;

    @include m1024() {
      min-width: 0;
      margin: 10px 10px 0 10px;
    }
  }
  a:hover {
    color: #459bd4;
  }
}
