.page-title__wrap-service {
  margin-bottom: 30px;
  border-bottom: 6px solid #49a3df;
}
.page-title-service {
  max-width: 520px;
  margin-bottom: 0;
}
.container-servise {
  padding-right: 230px;

  @include m768() {
    padding-right: 20px;
  }
  @include m425() {
    padding-right: 10px;
  }
}
.service__wrap {
  padding-right: 230px;

  @include m768() {
    padding-right: 0;
  }
}
.service-article {
  margin-bottom: 30px;
}
.service-article__title {
  font-size: 24px;
  margin-bottom: 15px;
  text-transform: none;
  color: #0276a1;

  @include pt(bold);
}
.service-article__text {
  font-size: 16px;
  line-height: 20px;
  color: #424242;

  @include pt(regular);
}
.service-aside {
  position: absolute;
  top: 10px;
  right: 20px;
  width: 400px;

  @include m768() {
    position: static;
    margin: 0 auto;
  }
  @include m425() {
    width: 100%;
  }
}
.service-aside__pic__wrap {
  display: flex;
  flex-direction: row;
  margin-bottom: -170px;

  justify-content: space-between;
  flex-wrap: wrap;
  @include m425() {
    margin-bottom: -100px;

    justify-content: space-around;
  }
}
.service-aside__pic-col {
  width: 190px;

  @include m425() {
    width: 40%;
  }
  &:first-child {
    margin-top: 100px;

    @include m768() {
      margin-top: 0;
    }
  }
}
.service-aside__pic {
  overflow: hidden;
  height: 190px;
  margin-bottom: 15px;

  @include m425() {
    height: 150px;
  }
  @include m375() {
    height: 120px;
  }
  img {
    display: block;
    width: 100%;
    min-height: 100%;

    object-fit: cover;
  }
}
.service-aside {
  .service__links-wrap {
    width: 80%;
    margin: 0 auto;
    padding: 190px 10px 10px 10px;
    border: 4px solid #49a3df;

    @include m425() {
      width: 100%;
      padding: 110px 0 10px 0;
      border: none;
    }
  }
}
