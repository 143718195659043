*,
*::before,
*::after {
  box-sizing: inherit;
}
html,
body {
  width: 100%;
  margin: 0;
  padding: 0;
}
html {
  font-size: 16px;
  line-height: 1.1;
  box-sizing: border-box;
  height: 100%;

  @include pt(regular);
}
body {
  display: flex;
  flex-direction: column;
  min-width: 320px;
  min-height: 100%;
  color: #3f3f3f;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-weight: 400;
  margin: 0 0 15px 0;
}
h1 {
  font-size: 24px;
  line-height: 28px;
  text-transform: uppercase;
  color: #0570a1;

  @include pt(bold);
}
h2 {
  font-size: 24px;
  line-height: 28px;
  text-transform: uppercase;
  color: #0570a1;

  @include gilroy(bold);
}
h3 {
  font-size: 18px;
  line-height: 20px;
  text-transform: uppercase;
  color: #49a3df;

  @include pt(bold);
}
h4 {
  font-size: 18px;
  line-height: 20px;
  color: #424242;

  @include pt(bold);
}
h5 {
  font-size: 18px;
  line-height: 20px;
  color: #49a3df;

  @include gilroy(bold);
}
h6 {
  font-size: 16px;
  line-height: 20px;
  color: #828282;

  @include pt(bold);
}
p {
  font-size: 16px;
  line-height: 20px;
  color: #424242;

  @include pt(regular);
}
a {
  transition: .5s;
  color: #0570a1;
  &:hover {
    transition: .5s;
    text-decoration: none;
    color: #49a3df;
  }
}
ol,
ul {
  li {
    padding: 3px 0;
  }
}
ul {
  li {
    position: relative;
    list-style: none;
    &::before {
      position: absolute;
      top: 8px;
      left: -16px;
      width: 8px;
      height: 8px;
      content: '';
      background-color: #49a3df;
    }
  }
}
.content {
  flex: 1 0 auto;
  position: relative;
}
.container {
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 15px 30px;

  @include m768() {
    padding: 10px 20px;
  }
  @include m425() {
    padding: 10px;
  }
}
.header,
.page-header,
.footer {
  flex: 0 0 auto;
}
.page-title__wrap {
  position: relative;
  padding: 10px 0;
  &::before {
    position: absolute;
    top: 21px;
    left: -1020px;
    display: block;
    width: 1000px;
    height: 4px;
    content: '';
    background-color: #49a3df;
  }
}
.page-title {
  font-size: 24px;
  line-height: 28px;
  text-transform: uppercase;
  color: #0570a1;

  @include pt(bold);
  @include m425() {
    font-size: 20px;
    line-height: 24px;
  }
}