.pagination {
  padding: 20px 0;
  text-align: center;
}
.pagination__list {
  display: inline-block;
  margin: 0;
  padding: 0;
  vertical-align: middle;
.pagination__item:before {
  display: none;
}
}
.pagination__item {
  display: inline-block;
  margin: 0 5px;
  transition: all .5s;
  &.active {
    .pagination__link {
      transition: all .5s;
      color: #0570a1;
      border: 1px solid #0570a1;
    }
  }
}
.pagination__link {
  font-size: 20px;
  display: inline-block;
  padding: 6px 10px;
  transition: all .5s;
  text-align: center;
  text-decoration: none;
  color: #363636;
  border: 1px solid transparent;
  border-radius: 5px;
  @include m425() {
    font-size: 18px;
    padding: 3px 7px 4px 7px;
  }
  &:hover {
    transition: all .5s;
    color: #0570a1;
    border: 1px solid #0570a1;
  }
}
.pagination-arr__left,
.pagination-arr__right {
  background-image: url('../img/pagination-arr.png');
  background-repeat: no-repeat;
  &:hover {
    .pagination__link {
      border: 1px solid transparent;
    }
  }
}
.pagination-arr__left {
  background-position: 0 0%;
}
.pagination-arr__right {
  background-position: 100% 0%;
}
