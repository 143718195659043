.contacts-content {
  min-height: 800px;
}
.contacts-map {
  position: absolute;
  top: 75px;
  right: 0;
  bottom: 0;
  left: 0;
}
.contacts-banner__wrap {
  position: relative;
  z-index: 1;
  top: -95px;
  width: 100%;
  margin: 0 auto;
}
.contacts-banner {
  background-color: #fff;
  .contacts-banner-inn {
    display: flex;
    flex-direction: row;
    max-width: 1100px;
    margin: 0 auto;
    margin-top: 60px;
    padding: 20px 0;

    justify-content: space-around;
    flex-wrap: wrap;
    @include m768() {
      flex-direction: column;
      width: 100%;

      align-items: center;
    }
  }
}
.contacts-banner__item {
  display: flex;
  flex-direction: row;
  padding: 5px 30px 5px 10px;
  border-right: 3px solid #cfdde9;

  justify-content: center;
  align-items: center;
  @include m1000() {
    padding: 5px 5px 5px 20px;

    justify-content: flex-start;
  }
  @include m768() {
    max-width: 340px;
    border-right: none;
  }
  &:last-child {
    border-right: none;
  }
  .svg-circle {
    display: flex;
    width: 60px;
    height: 60px;
    margin-right: 20px;
    border: 2px solid #459bd4;
    border-radius: 50%;

    flex: 0 0 auto;
    justify-content: center;
    align-items: center;
    svg {
      width: 33px;
      height: 33px;

      fill: #459bd4;
    }
  }
}
.contacts-banner__title {
  font-size: 18px;
  margin-bottom: 10px;
  text-transform: uppercase;
  color: #459bd4;

  @include pt(bold);
}
.contacts-banner__link {
  font-size: 14px;
  line-height: 18px;
  text-decoration: none;
  color: #000d1c;

  @include pt(regular);
}

.contacts-banner__item--adress {
  width: 36%;

  @include m768() {
    width: 100%;
  }
}
.contacts-banner__item--phone {
  width: 32%;

  @include m768() {
    width: 100%;
  }
}
.contacts-banner__item--mail {
  width: 32%;

  @include m768() {
    width: 100%;
  }
}
