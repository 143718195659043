.objects-map-content {
  min-height: 600px;

}
.objects-map {
  position: absolute;
  top: 75px;
  right: 0;
  bottom: 0;
  left: 0;
  @include m768() {
    top: 60px;
  }
  @include m375() {
    top: 80px;
  }
}