@font-face {
  font-family: 'PTSans-Regular';
  src: url('../fonts/PTSans-Regular/PTSans-Regular.eot');
  src: url('../fonts/PTSans-Regular/PTSans-Regular.woff') format('woff'),
  url('../fonts/PTSans-Regular/PTSans-Regular.ttf') format('truetype'),
  url('../fonts/PTSans-Regular/PTSans-Regular.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'PTSans-Bold';
  src: url('../fonts/PTSans-Bold/PTSans-Bold.eot');
  src: url('../fonts/PTSans-Bold/PTSans-Bold.woff') format('woff'),
  url('../fonts/PTSans-Bold/PTSans-Bold.ttf') format('truetype'),
  url('../fonts/PTSans-Bold/PTSans-Bold.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy-Extrabold';
  src: url('../fonts/Gilroy-Extrabold/Gilroy-Extrabold.eot');
  src: url('../fonts/Gilroy-Extrabold/Gilroy-Extrabold.woff') format('woff'),
  url('../fonts/Gilroy-Extrabold/Gilroy-Extrabold.ttf') format('truetype'),
  url('../fonts/Gilroy-Extrabold/Gilroy-Extrabold.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}