.circle__wrap {
  position: absolute;
  top: 350px;
  left: -480px;
  width: 280px;
  height: 280px;
  animation: pulse 4s linear infinite;
  border-radius: 50%;

  @include m1024() {
    left: -450px;
  }
  @include m1000() {
    top: 140px;
    right: 0;
    left: 0;
    margin: auto;
  }
  @include m680() {
    width: 200px;
    height: 200px;
  }
  @include m530() {
    display: none;
  }
}
.circle__pic {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: .5s;
  opacity: 0;
  border-radius: 50%;
  background-size: cover;
}
.circle__pic-one {
  background-image: url('../img/circle-pic-01.jpg');
}
.circle__pic-two {
  background-image: url('../img/circle-pic-02.jpg');
}
.circle__pic-three {
  background-image: url('../img/circle-pic-03.jpg');
}
.circle__block {
  position: absolute;
  svg {
    width: 100%;
    height: 100%;
    transition: .5s;

    fill: #fff;
  }
  &.active {
    svg {
      fill: #03f9ff;
    }
    .circle__link {
      color: #03f9ff;
    }
  }
}
.circle__link {
  font-size: 20px;
  line-height: 24px;
  position: absolute;
  z-index: 1;
  transition: .5s;
  text-decoration: none;
  text-transform: uppercase;
  color: #fff;

  @include gilroy(bold);
  @include m680() {
    font-size: 16px;
    line-height: 20px;
  }
  &:hover {
    color: #fff;
  }
}
.circle__block-one {
  top: 10px;
  left: -190px;
  width: 300px;
  height: 40px;

  @include m680() {
    left: -160px;
    width: 250px;
  }
  .circle__link {
    top: -55px;
    right: -30px;
    left: 60px;
    padding-bottom: 200px;

    @include m1000() {
      padding: 0;
    }
    @include m680() {
      top: -45px;
      right: -10px;
      left: 40px;
    }
  }
}
.circle__block-two {
  top: 25px;
  left: 240px;
  width: 175px;
  height: 30px;

  @include m680() {
    left: 180px;
    width: 140px;
  }
  .circle__link {
    top: -52px;
    right: 0;
    padding-bottom: 160px;
    padding-left: 120px;

    @include m1000() {
      padding: 0;
    }
    @include m680() {
      top: -39px;
    }
  }
}
.circle__block-three {
  top: 270px;
  left: 160px;
  width: 260px;
  height: 70px;

  @include m680() {
    top: 160px;
    left: 120px;
    width: 200px;
  }
  .circle__link {
    right: 5px;
    bottom: 5px;
    padding-top: 100px;
    padding-left: 200px;

    @include m1000() {
      padding: 0;
    }
    @include m680() {
      right: 0;
      bottom: 15px;
    }
  }
}
.circle__svg {
  position: relative;
  width: 100%;
  height: 100%;
  svg {
    width: 100%;
    height: 100%;
    #trans {
      fill: transparent;
    }
    #one {
      transition: .5s;

      fill: #fff;
    }
    #two {
      transition: .5s;

      fill: #fff;
    }
    #three {
      transition: .5s;

      fill: #fff;
    }
  }
}
.circle__block-one.active {
  & ~ .circle__pic-one {
    transition: .5s;
    opacity: 1;
  }
  & ~ .circle__svg {
    svg {
      #one {
        transition: .5s;

        fill: #03f9ff;
      }
    }
  }
}
.circle__block-two.active {
  & ~ .circle__pic-two {
    transition: .5s;
    opacity: 1;
  }
  & ~ .circle__svg {
    svg {
      #two {
        transition: .5s;

        fill: #03f9ff;
      }
    }
  }
}
.circle__block-three.active {
  & ~ .circle__pic-three {
    transition: .5s;
    opacity: 1;
  }
  & ~ .circle__svg {
    svg {
      #three {
        transition: .5s;

        fill: #03f9ff;
      }
    }
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 20px 0 rgba(3,249,255,1);
  }
  25% {
    box-shadow: 0 0 20px 5px rgba(3,249,255,1);
  }
  50% {
    box-shadow: 0 0 20px 10px rgba(3,249,255,1);
  }
  75% {
    box-shadow: 0 0 20px 5px rgba(3,249,255,1);
  }
  100% {
    box-shadow: 0 0 20px 0 rgba(3,249,255,1);
  }
}
