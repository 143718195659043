.service__row {
  display: flex;
  flex-direction: row;
  margin-bottom: 40px;

  flex-wrap: no-wrap;
  @include m1000() {
    flex-direction: column-reverse;
    margin-bottom: 20px;

    align-items: center;
  }
  &:nth-child(even) {
    flex-direction: row-reverse;

    @include m1000() {
      flex-direction: column-reverse;
    }
    .service__item--text {
      margin: 30px -3% 0 0;
      padding: 25px 70px 25px 40px;

      @include m1000() {
        margin: 0 0 -50px 0;
        padding: 25px 40px 70px 40px;
      }
      @include m425() {
        margin: 0 0 -30px 0;
        padding: 25px 20px 40px 20px;
      }
    }
  }
}
.service__item {
  width: 52%;
}
.service__item--pic {
  @include m1000() {
    width: 90%;
    max-width: 600px;
  }
}
.service__img-wrap {
  box-shadow: 0 0 20px 5px rgba(69, 155, 212, .38);
}
.service__img {
  overflow: hidden;
  max-height: 500px;
  margin-bottom: 50px;
  img {
    display: block;
    width: 100%;
  }
}
.service__item--text {
  z-index: 1;
  margin: 30px 0 0 -3%;
  padding: 25px 40px 25px 70px;
  border: 4px solid #49a3df;

  @include m1000() {
    width: 100%;
    max-width: 800px;
    margin: 0 0 -50px 0;
    padding: 25px 40px 70px 40px;
  }
  @include m425() {
    margin: 0 0 -30px 0;
    padding: 25px 20px 40px 20px;
  }
  p {
    font-size: 14px;
    line-height: 18px;
    color: #424242;

    @include pt(regular);
  }
}
.service__item--number {
  font-size: 72px;
  margin: 20px 0;
  color: #49a3df;

  @include gilroy(bold);
  @include m425() {
    font-size: 60px;
    margin-bottom: 10px;
  }
}
.service__item--title {
  font-size: 30px;
  padding: 20px 0;
  color: #49a3df;
  border-bottom: 4px solid #49a3df;

  @include gilroy(bold);
  @include m425() {
    font-size: 22px;
    padding: 20px 0 10px 0;
  }
}
.service__link {
  position: relative;
  width: 280px;
  margin: 0 auto 30px;
  svg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 15px;
    max-width: 30px;
    max-height: 30px;
    margin: auto;

    fill: #0570a1;
  }
  a {
    font-size: 18px;
    padding: 10px 10px 10px 60px;
    color: #0570a1;

    @include pt(bold);
    &:hover {
      text-decoration: none;
    }
  }
}
.servise-bottom {
  background-size: cover;
}
.servise-bottom__banner {
  width: 100%;
  max-width: 850px;
  margin: 0 auto;
  padding: 35px 0;
  text-align: center;
}
.servise-bottom__title {
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 0;
  text-transform: uppercase;
  color: #fff;

  @include gilroy(bold);
  @include m425() {
    font-size: 18px;
    line-height: 24px;
  }
}
.service-02__btn {
  font-size: 18px;
  position: relative;
  display: block;
  width: 370px;
  margin: 20px auto 0;
  padding: 20px 10px 17px 10px;
  transition: .5s;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  color: #0570a1;

  @include gilroy(bold);
  @include m530() {
    width: 300px;
  }
  @include m425() {
    font-size: 14px;
    max-width: 230px;
  }
  &:hover {
    color: #0570a1;
    text-shadow: none;
    rect {
      transition: all 1.35s cubic-bezier(.19, 1, .22, 1);
      transition: .5s;

      stroke-width: 5;
      stroke-dasharray: 20, 630;
      stroke-dashoffset: 50;
      @include m530() {
        stroke-dasharray: 20, 510;
      }
      @include m425() {
        stroke-dasharray: 20, 410;
      }
    }
  }
  svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 55px;

    @include m425() {
      height: 52px;
    }
  }
  rect {
    transition: .5s;

    fill: none;
    stroke: #0570a1;
    stroke-width: 4;
    stroke-dasharray: 830, 0;
  }
}
